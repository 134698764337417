import { mapToPageHeader, Banner } from "@components/Banner"
import { Layout } from "@components/Layout"
import { mapToSEO, SEO } from "@components/SEO"
import {
  mapToKiwiVIPVisible,
  SignupBannerWrapper,
} from "@components/SignupBanner"
import { graphql } from "gatsby"
import React from "react"
import { GenericComponentsWrapper } from "@components/GenericComponentsWrapper/GenericComponentsWrapper"

interface Props {
  data: any
}

class HowItWorksPage extends React.Component<Props> {
  render() {
    const elements = this.props.data?.allKontentItemGenericPageLayout?.nodes[0]?.elements
    if(elements === null || elements === undefined){
      return null
    }
    const { components } = elements
    const apiUrl = this.props.data.site.siteMetadata.apiUrl
    const metadata = mapToPageHeader(elements)
    const seoData = mapToSEO(elements)
    const kiwiVip = mapToKiwiVIPVisible(elements)

    return (
      <Layout version="standard">
        <SEO {...seoData} />
        <Banner {...metadata} isBiggerBanner={false} />
        <GenericComponentsWrapper linked_items={components.linked_items} apiUrl={apiUrl} reCaptchaSiteKey={this.props.data.site.siteMetadata.reCaptchaSiteKey} 
        reCaptchaSiteKeyV2={this.props.data.site.siteMetadata.reCaptchaSiteKeyV2} emailValidationToken={this.props.data.site.siteMetadata.emailValidationToken} backgroundColor='#f9f9f9' darktheme={false}/>
        {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
      </Layout>
    )
  }
}

export default HowItWorksPage
export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
        emailValidationToken
        reCaptchaSiteKey
        reCaptchaSiteKeyV2
      }
    }
    allKontentItemGenericPageLayout(filter: {elements: {general_page_content__title: {value: {eq: "How It Works"}}}}) {
      nodes {
        elements {
          seo__page_title {
            value
          }
          seo__page_description {
            value
          }
          seo__noindex {
            value {
              name
            }
          }
          seo__nofollow {
            value {
              name
            }
          }
          url {
            value
          }
          general_page_content__title {
            value
          }
          general_page_content__kiwivip_signup {
            value {
              name
              codename
            }
          }
          general_page_content__kiwivip_signup_version {
            value {
              name
              codename
            }
          }
          general_page_content__responsive_header_image {
            linked_items {
              ... on KontentItemBannerImage {
                id
                elements {
                  desktop {
                    value {
                      url
                      description
                    }
                  }
                  mobile {
                    value {
                      description
                      url
                    }
                  }
                  tablet {
                    value {
                      description
                      url
                    }
                  }
                }
              }
            }
          }
          components {
            itemCodenames
            linked_items {
              ... on KontentItemPageTopStepsContainer {
                __typename
                elements {
                  title {
                    value
                  }
                  description {
                    value
                  }
                  redirect_steps {
                    linked_items {
                      ... on KontentItemRedirectStepItem {
                        elements {
                          step_link {
                            value
                          }
                          step_info {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on KontentItemImagecontentlist {
                __typename
                elements {
                  title {
                    value
                  }
                  description {
                    value
                  }
                  listitem {
                    linked_items {
                      ... on KontentItemImagecontentlistitem {
                        elements {
                          title {
                            value
                          }
                          description {
                            value
                          }
                          image {
                            value {
                              url
                              name
                              description
                            }
                          }
                          components {
                            __typename
                            linked_items {
                              ... on KontentItemListContainer {
                                elements {
                                  bottom_text {
                                    value
                                  }
                                  items {
                                    linked_items {
                                      __typename
                                      ... on KontentItemProductCategoryFormats {
                                        elements {
                                          __typename
                                          base_price {
                                            value
                                          }
                                          description {
                                            value
                                          }
                                          title {
                                            value
                                          }
                                          example_image {
                                            value {
                                              url
                                              description
                                            }
                                          }
                                          format_type {
                                            value {
                                              codename
                                              name
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on KontentItemContentlist {
                                __typename
                                elements {
                                  listitems {
                                    linked_items {
                                      ... on KontentItemContentlistitem {
                                        __typename
                                        elements {
                                          title {
                                            value
                                          }
                                          content {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on KontentItemImageLinkBanner {
                __typename
                system {
                  codename
                }
                elements {
                  image {
                    value {
                      url
                    }
                  }
                  title {
                    value
                  }
                  button__text {
                    value
                  }
                  button__button_type {
                    value {
                      codename
                    }
                  }
                  button__button_color_theme {
                    value {
                      codename
                    }
                  }
                  button__url_slug {
                    value
                  }
                }
              }
              ... on KontentItemFaqBlock {
                __typename
                system {
                  codename
                }
                elements {
                  title {
                    value
                  }
                  background_colors {
                    value {
                      codename
                    }
                  }
                  faqs {
                    linked_items {
                      ... on KontentItemFaqItem {
                        elements {
                          question {
                            value
                          }
                          answer {
                            value
                          }
                          faq_category {
                            value {
                              name
                              codename
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on KontentItemInfoBannerContainer {
                __typename
                system {
                  codename
                  name
                }
                elements {
                  background_colors {
                    value {
                      codename
                    }
                  }
                  text_colors {
                    value {
                      codename
                    }
                  }
                  text_alignment {
                    value {
                      codename
                    }
                  }
                  items {
                    linked_items {
                      ... on KontentItemInfoBannerItem {
                        elements {
                          icon {
                            value {
                              url
                            }
                          }
                          title {
                            value
                          }
                          description {
                            value
                          }
                          linkurl {
                            value
                          }
                          linktext {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on KontentItemCallToActionCardWithImageContainer {
                elements {
                  title {
                    value
                  }
                  subtitle {
                    value
                  }
                  background_colors {
                    value {
                      codename
                      name
                    }
                  }
                  linked_items {
                    linked_items {
                      ... on KontentItemCallToActionImageCard {
                        elements {
                          text_colors {
                            value {
                              codename
                            }
                          }
                          button__button_type {
                            value {
                              codename
                            }
                          }
                          title {
                            value
                          }
                          background_image {
                            value {
                              name
                              description
                              url
                            }
                          }
                          subtitle {
                            value
                          }
                          button__text {
                            value
                          }
                          button__url_slug {
                            value
                          }
                          button__button_color_theme {
                            value {
                              codename
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
